import { withScope } from '@sentry/react';
import { type DefaultOptions, MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AjaxError } from 'rxjs/ajax';

export const queryClientOptions = {
  queries: {
    // this enables better structural sharing if the same request is fired at different times while rendering
    staleTime: 5000,
    // tbd -> refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
  },
} satisfies DefaultOptions;

// TODO: importing it directly might get some unwanted side-effects
// initialize this QueryClient where the QueryClientProvider is deployed
/** @deprecated please access the queryClient via useQueryClient */
export const queryClient = new QueryClient({
  // better logging in sentry by using the cache, so we can access the mutation and query
  // https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
  mutationCache: new MutationCache({
    onError: (err, variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          variables,
          mutationKey: mutation.options.mutationKey,
        });
        if (err instanceof AjaxError) {
          const errorStatusRange = err.status.toString()[0] + 'XX'; // 404 => 4XX
          scope.setLevel('info');
          scope.setTag('statusCode', err.status);
          scope.setTransactionName(`Mutation: API responded with ${errorStatusRange}`);
          scope.setContext('Response', JSON.parse(JSON.stringify(err.response)));
          scope.setContext('Headers', {
            'x-request-id': err.xhr.getResponseHeader('x-request-id'),
          });
          scope.setFingerprint([`mutation-ajax-error-${errorStatusRange}`]);
        }
        if (mutation.options.mutationKey) {
          scope.setTag(
            'mutationKey',
            canonicalizeQueryKeys(mutation.options.mutationKey.toString()),
          );
        }
        scope.captureException(err, {});
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        if (err instanceof AjaxError) {
          const errorStatusRange = err.status.toString()[0] + 'XX'; // 404 => 4XX
          scope.setLevel('info');
          scope.setTransactionName(`Query: API responded with ${errorStatusRange}`);
          scope.setTag('statusCode', err.status);
          scope.setContext('Response', JSON.parse(JSON.stringify(err.response)));
          scope.setContext('Headers', {
            'x-request-id': err.xhr.getResponseHeader('x-request-id'),
          });
          scope.setFingerprint([`query-ajax-error-${errorStatusRange}`]);
        }
        if (err instanceof Error && err.message.includes('data is undefined')) {
          scope.setLevel('warning');
          scope.setFingerprint([`query-error-data-is-undefined`]);
        }
        scope.setTag('queryHash', canonicalizeQueryKeys(query.queryHash));
        scope.setContext('query', { queryHash: query.queryHash });
        scope.captureException(err, {});
      });
    },
  }),
  defaultOptions: queryClientOptions,
});

const canonicalizeQueryKeys = (queryHash: string): string =>
  queryHash
    // unify sap system
    .replaceAll(/"sapSystem":\s*"[^"]+?([^"/]+)/g, '"sapSystem":"0')
    // unify kid
    .replaceAll(/"INTERNAL_USER_UI_KEY":\s*"[^"]+?([^"/]+)/g, '"INTERNAL_USER_UI_KEY":"0')
    // strip away all numbers
    .replaceAll(/\d/g, '0')
    .replaceAll(
      // UUIDs
      /\/[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ABab][\da-f]{3}-[\da-f]{12}/g,
      '<uuid>',
    )
    .replaceAll(/filtering":{[^{]*}/g, 'filtering":{...}')
    .replaceAll(/pagination":{[^{]*}/g, 'pagination":{...}')
    .replaceAll(/sorting":{[^{]*}/g, 'sorting":{...}');
